import { Oval } from 'react-loader-spinner';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './ResultsTrainSelectionPage.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SelectedTrain from './SelectedTrain/SelectedTrain';
import TrainChoiceItem from './TrainChoiceItem/TrainChoiceItem';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { format } from 'date-fns';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import FilterButton from '@components/FilterButton/FilterButton';
import TextEllipsisWithPopup from '@components/TextEllipsisWithPopup/TextEllipsisWithPopup';
import useFilterTrainOffers from 'src/Pages/_Components/Filter/FilterV3/FilterTrainOffers/useFilterTrainOffers';
import FilterModal from 'src/Pages/_Components/Filter/FilterV3/FilterModal/FilterModal';
import { Calendar } from '@assets/icons/icons';
import Button from '@components/Button/Button';
import SendLinkButton from 'src/Pages/_Components/SendSefCareLinks/SendLinkButton';
import { isAppEnvProductionLike } from 'src/appEnv';
import useSendLinkButtons from 'src/Pages/_Components/SendSefCareLinks/useSendLinkButtons';
import { useTranslation } from 'react-i18next';
import HeaderV2 from 'src/layout/Header/V2/HeaderV2';

export default function ResultsTrainSelectionPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { canSendSelfCareLink } = useSendLinkButtons();
  const { missionContext, callAction, isCurrentStepReadOnly } = useCurrentMission();
  let defaultProposalIndex = 0;
  let defaultCabinClassSelectedIndex = 0;
  let defaultOfferIndex = 0;

  const selectedOfferId = "d83553b1-17c1-48ae-a077-4f321b806db2";// missionContext?.steps.searchTrain?.selectedOfferId;
  if (selectedOfferId) {
    const searchTrainStep = missionContext?.steps.searchTrain;
    searchTrainStep?.proposals.forEach((proposal, i) => {
      proposal.cabinClasses.forEach((cabinClass, j) => {
        cabinClass?.offers.forEach((offer, k) => {
          if (offer.id === selectedOfferId) {
            defaultProposalIndex = i;
            defaultCabinClassSelectedIndex = j;
            defaultOfferIndex = k;
          }
        });
      });
    });
  }

  const searchTrainStep = missionContext?.steps.searchTrain;
  const defaultProposalSelected = searchTrainStep?.proposals[defaultProposalIndex];

  const {
    idsFiltered,
    countNbFiltersApplied,
    onClickButtonFilter,
    modalProps,
    resetFilters,
  } = useFilterTrainOffers(searchTrainStep?.proposals || []);

  if (!selectedOfferId) {
    for (let i = 0; i < (defaultProposalSelected?.cabinClasses.length || 0); i++) {
      const cabinClass = defaultProposalSelected?.cabinClasses[i];
      if (cabinClass) {
        defaultCabinClassSelectedIndex = i;
        break;
      }
    }
  }

  const [selectedProposalCabinClass, setSelectedProposalCabinClass] = useState<{
    proposalIndex: number;
    cabinClassIndex: number;
    offerIndex: number;
  } | null>({
    proposalIndex: 0,
    cabinClassIndex: defaultCabinClassSelectedIndex,
    offerIndex: defaultOfferIndex,
  });

  const getSelectedCabinClass = useCallback(() => {
    if (!selectedProposalCabinClass) {
      return null;
    }
    const proposal = searchTrainStep?.proposals[selectedProposalCabinClass.proposalIndex];
    if (!proposal) {
      return null;
    }
    return proposal.cabinClasses[selectedProposalCabinClass.cabinClassIndex];
  }, [searchTrainStep, selectedProposalCabinClass]);

  useEffect(() => {
    if (!searchTrainStep) {
      return;
    }
    if (selectedProposalCabinClass === null) {
      return;
    }
    const selectedCabinClass = getSelectedCabinClass();
    if (!selectedCabinClass || selectedCabinClass.offers.some(i => idsFiltered[i.id])) {
      return;
    }
    setSelectedProposalCabinClass(null);
  }, [getSelectedCabinClass, idsFiltered, searchTrainStep, selectedProposalCabinClass]);

  const hasResults = useMemo(() => {
    return !!searchTrainStep?.proposals.some((proposal) => {
      const hasAnyOfferThatMatchFilter = proposal.cabinClasses.some((cabinClass) => {
        return cabinClass?.offers.some((offer) => {
          const offerId = offer.id;
          return idsFiltered[offerId];
        });
      });
      return hasAnyOfferThatMatchFilter;
    })
  }, [idsFiltered, searchTrainStep?.proposals]);

  if (!searchTrainStep || !missionContext?.steps?.searchTrain) {
    return null;
  }

  const dateTime = new Date(searchTrainStep.date);

  const isOfferSelected = selectedProposalCabinClass !== null;

  const fetchNext = async () => {
    if (!searchTrainStep.getNextTrainsAction) {
      return;
    }
    setLoading(true);
    try {
      await callAction(searchTrainStep.getNextTrainsAction);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(" "),
      }}
    >

      <FilterModal
        {...modalProps}
      />

      <HeaderV2 />

      <div data-testid="step-searchTrain" className={[layoutStyles.content, styles.content].join(" ")}>

        <CurrentMissionVerticalSteps
          displayBackButton
        />

        <div className={styles.trainResultsContainer}>

          <div className={styles.sectionHeader}>
            <TextEllipsisWithPopup
              containerProps={{
                className: styles.sectionHeaderTitle,
              }}
            >{t('trip', { origin: searchTrainStep.origin.name, destination: searchTrainStep.destination.name })}</TextEllipsisWithPopup>
          </div>

          <div className={styles.trainListAndChoiceContainer}>

            <div className={styles.trainListContainer}>

              {hasResults && (<div className={styles.trainListHeader}>
                <div className={styles.date}>{format(dateTime, "PPPP")}</div>
                <div className={styles.cabinClassLabels}>
                  {searchTrainStep.cabinClasses.map((cabinClass, i) => (
                    <div key={i} className={styles.cabinClassLabel}>{cabinClass.label}</div>
                  ))}
                </div>
              </div>)}

              <div className={styles.trainList}>

                {!hasResults && (
                  <>
                    <div className={styles.noResults}>
                      {t('no_results_explanation')}
                    </div>
                    <Button
                      type="secondary"
                      label={t('resetFilters')}
                      onClick={() => {
                        resetFilters();
                      }}
                    />
                  </>
                )}

                {searchTrainStep.proposals.map((proposal, i) => {

                  const hasAnyOfferThatMatchFilter = proposal.cabinClasses.some((cabinClass) => {
                    return cabinClass?.offers.some((offer) => {
                      const offerId = offer.id;
                      return idsFiltered[offerId];
                    });
                  });

                  if (!hasAnyOfferThatMatchFilter) {
                    return null;
                  }

                  let headerDate = null;
                  if (searchTrainStep.proposals[i - 1]) {
                    const previousDate = new Date(searchTrainStep.proposals[i - 1].origin.datetime);
                    const currentDate = new Date(proposal.origin.datetime);

                    // If date are not the same day, display the date
                    if (currentDate.getDate() !== previousDate.getDate()) {
                      headerDate = (
                        <div className={styles.trainListHeader}>
                          <div className={`${styles.date} ${styles.dateHighlight}`}>
                            <Calendar height={18} width={18} />
                            {format(currentDate, "PPPP")}
                          </div>
                          <div className={styles.cabinClassLabels}>
                            {searchTrainStep.cabinClasses.map((cabinClass, i) => (
                              <div key={i} className={styles.cabinClassLabel}>{cabinClass.label}</div>
                            ))}
                          </div>
                        </div>
                      )
                    }
                  }

                  return (
                    <React.Fragment key={i}>
                      {headerDate}
                      <TrainChoiceItem
                        containerProps={{
                          className: styles.trainChoiceItem,
                        }}
                        isReadOnly={isCurrentStepReadOnly()}
                        selectedCabinClassIndex={selectedProposalCabinClass?.proposalIndex === i ? selectedProposalCabinClass.cabinClassIndex : null}
                        onClickPrice={(cabinClassIndex) => {
                          setSelectedProposalCabinClass({
                            proposalIndex: i,
                            cabinClassIndex,
                            offerIndex: 0,
                          });
                        }}
                        proposal={{
                          ...proposal,
                          cabinClasses: proposal.cabinClasses.map((cabinClass) => {
                            if (!cabinClass) {
                              return null;
                            }
                            return {
                              ...cabinClass,
                              offers: cabinClass.offers.filter((offer) => {
                                const offerId = offer.id;
                                return idsFiltered[offerId];
                              }),
                            }
                          }).filter((cabinClass) => cabinClass !== null)
                        }}
                        cabinClassesIndexToHide={proposal.cabinClasses.map((cabinClass, i) => {
                          const hasAnyOfferThatMatchFilter = cabinClass?.offers.some((offer) => {
                            const offerId = offer.id;

                            return idsFiltered[offerId];
                          });

                          return hasAnyOfferThatMatchFilter ? null : i;
                        }).filter((i) => i !== null) as Array<number>}
                      />
                    </React.Fragment>
                  )
                })}

                {!isCurrentStepReadOnly() && hasResults && searchTrainStep.getNextTrainsAction && (
                  <div onClick={() => {
                    if (loading) {
                      return;
                    }
                    fetchNext();
                  }} className={styles.displayMoreButton}>
                    <span>{t('nextTrains')}</span>
                    {loading && (<Oval
                      height={20}
                      width={20}
                      color="black"
                      secondaryColor="white"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />)}

                  </div>
                )}
              </div>

            </div>

          </div>
        </div>

        <div className={styles.selectedTrainContainer}>

          <div className={`flex flex-row items-center gap-5 ${styles.filterButtonContainer}`}>
            {canSendSelfCareLink && (
              <SendLinkButton
                type='search'
                label={t('sendSelfCareLink')}
                containerProps={{
                  className: styles.sendLinkButton,
                  disabled: isCurrentStepReadOnly() || isAppEnvProductionLike(),
                }}
              />
            )}
            {!isCurrentStepReadOnly() && (
              <FilterButton
                countNbFiltersApplied={countNbFiltersApplied}
                onClick={onClickButtonFilter}
              />
            )}
          </div>

          {isOfferSelected && (
            <SelectedTrain
              isLoading={loading}
              containerProps={{
                className: styles.selectedTrain,
              }}
              readOnly={isCurrentStepReadOnly()}
              offerIndex={selectedProposalCabinClass.offerIndex}
              cabinClassIndex={selectedProposalCabinClass.cabinClassIndex}
              proposal={{
                ...searchTrainStep.proposals[selectedProposalCabinClass.proposalIndex],
                cabinClasses: searchTrainStep.proposals[selectedProposalCabinClass.proposalIndex].cabinClasses.map((cabinClass) => {
                  if (!cabinClass) {
                    return null;
                  }
                  return {
                    ...cabinClass,
                    offers: cabinClass.offers.filter((offer) => {
                      const offerId = offer.id;
                      return idsFiltered[offerId];
                    }),
                  }
                }).filter((cabinClass) => cabinClass !== null)
              }}
              onClickOffer={(offerIndex) => {
                setSelectedProposalCabinClass({
                  ...selectedProposalCabinClass,
                  offerIndex,
                });
              }}
            />
          )}

        </div>
      </div>


    </PageContainer>
  )
}